import React from 'react'
import Header from '../components/header'
import { Container, ListGroup, ListGroupItem } from 'reactstrap'
import Seo from '../components/SEO'
import { navigate } from 'gatsby'
import Layout from '../components/layout'
import SiteConfig from '../../config/SiteConfig.json' 
import _ from 'lodash';

export default class CategoryTamplate extends React.Component {

    render(){ 
        let category = this.props.pageContext.category;
        let posts = this.props.pageContext.posts;
        let lang = this.props.pageContext.lang;

        return (
            <Layout lang={lang}>
                <Seo 
                postTitle={`${category} - ${SiteConfig.seo.siteName}`}
                postURL={this.props.location.pathname}
                postLanguage={lang}
                />
                <Header lang={lang} />
                <Container style={{padding: '40px 0'}}>
                    <h2 className='text-center mb-5'>{_.replace(category,'Amp','&')}</h2>
                    <ListGroup style={{maxWidth: '730px', margin: '0 auto'}}>
                    {
                        posts.map((post) => {
                            if(SiteConfig.brand === "Blix" && !_.includes(post.title,"BlueMail") && !_.includes(post.title,"TypeApp")){
                                return(
                                    <ListGroupItem key={post.id} onClick={() => navigate(post.meta)} className='category-title'>
                                        {post.title}
                                    </ListGroupItem>
                                )
                            }else if(SiteConfig.brand === "BlueMail" && !_.includes(post.title,"Blix") && !_.includes(post.title,"TypeApp")){
                                return(
                                    <ListGroupItem key={post.id} onClick={() => navigate(post.meta)} className='category-title'>
                                        {post.title}
                                    </ListGroupItem>
                                )
                            }else if(SiteConfig.brand === "TypeApp" && !_.includes(post.title,"Blix") && !_.includes(post.title,"BlueMail")){
                                return(
                                    <ListGroupItem key={post.id} onClick={() => navigate(post.meta)} className='category-title'>
                                        {post.title}
                                    </ListGroupItem>
                                )
                            }
                            return '';
                        })
                    }
                    </ListGroup>
                </Container>
            </Layout>
        )
    }
}

